@import '../../assets/sass/styles.scss';

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo-container {
    padding: 100px;
    img {
      max-width: 150px;
      position: absolute;
      right: 40px;
      top: 25px;
    }
  }
  .banner-container {
    position: relative;
    .logo {
      position: absolute;
      top: 10px;
      left: 10px;
      max-height: 30%;
    }
    h2 {
      font-size: 4.6rem;
      font-weight: 500;
      bottom: 35%;
      left: 5%;
      position: absolute;
      color: white;
    }
    .banner-image {
      justify-content: flex-start;
      align-items: center;
      display: flex;
      text-align: center;
      background-image: url('../../assets/images/banana_banner.jpg');
      background-position: center center;
      background-size: contain;
      background-color: $secondary-bg-color;
      background-repeat: no-repeat;
      width: 100vw;
      min-height: 50vh;

      img {
        // max-height: 60vh;
        position: relative;
        width: 100vw;
      }
    }
  }
  .description-container {
    .campaign-description {
      font-size: 1.5rem;
      font-weight: 400;
      color: $primary;
      margin: 60px 0 40px 0;
      // line-height: 1;
      text-align: center;
      .bold {
        font-weight: 600;
        font-size: 2rem;
      }
      //   max-width: 60%;
    }
    .campaign-period {
      text-align: center;
      font-size: 1.4rem;
    }
  }
  .nav-container {
    margin: 20px auto;
    .button {
      margin: 10px;
    }
  }
}

@media screen and (max-width: 769px) {
  .Header {
    .logo-container {
      padding: 20px;
      img {
        position: relative;
        right: 0px;
        top: 0px;
      }
    }
    .banner-container {
      background-color: $background-accent;
      padding-top: 10%;
      padding-bottom: 10%;
      .logo {
        top: 20%;
        max-height: 25%;
      }
      h2 {
        font-size: 1rem;
        font-weight: 500;
        // padding: 1rem 0;
        max-width: 100%;
        bottom: 35%;
      }
      .banner-image {
        align-items: flex-start;
        background-size: cover;
        justify-content: center;
      }
    }
    .description-container {
      .campaign-description {
        font-size: 1rem;
        padding: 0 20px;
        .bold {
          font-size: 1.2rem;
        }
      }
      .campaign-period {
        font-size: 1rem;
      }
    }
    .nav-container {
      margin: 20px auto;
      text-align: center;
      .button {
        width: 70vw;
      }
    }
  }
}
