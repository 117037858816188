@import './assets/sass/styles.scss';

.App {
  button.button,
  a.button {
    background-color: $button-bg-color;
    border: 2px solid $accent;
    padding: 22px 45px;
    color: $secondary;
    border-radius: 10px;
    font-size: 1.4rem;
    cursor: pointer;
    &:hover {
      background-color: $button-bg-hover-color;
    }
  }
}

@media screen and (max-width: 769px) {
  .App {
    button.button,
    a.button {
      font-size: 1.1rem;
    }
  }
}
